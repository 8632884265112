import { DialogMixin } from '@/mixins/dialog';
import { ProjectProductDetail, SaveProjectProduct } from '@/resource/model';
import { dateFormat, messageErrors, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { PrepressChangeColorCraftsEnum, PrepressLayoutCraftsEnum, ProjectItemTypeEnum } from '@/resource/enum';
import { ProjectItemModule } from '@/store/modules/project-item';
import ProjectProductForm from '../../project-product-form/project-product-form.vue';
import { cloneDeep } from 'lodash-es';
@Component({
  components: { ProjectProductForm }
})
export default class EditProduct extends mixins(DialogMixin) {
  @Prop({
    type: Object,
    required: true,
    default: {}
  })
  public item!: ProjectProductDetail;

  public type: string = 'revision';

  @InjectReactive()
  public readonly projectCode!: string;

  @InjectReactive()
  public readonly projectId!: number;

  @InjectReactive()
  public readonly customerId!: number;

  public projectProductForm: Partial<ProjectProductDetail> = {};

  public loaded = false;

  public title = 'project.editItem';

  public changeLog: Array<{
    propName: string;
    oldValue: any;
    newValue: any;
  }> = [];

  private oldProjectProduct: Partial<ProjectProductDetail> = {};

  // public get isAllowEditItemType(): boolean {
  //   return (
  //     this.oldProjectProduct.prepressStatus === PrepressStatusEnum.new &&
  //     this.oldProjectProduct.makeStatus === ProjectProductStatusEnum.new
  //   );
  // }

  /**
   * 当前修改行为是否定性为改稿
   */
  public get revision(): boolean {
    // if (!this.oldProjectProduct.prepressStatus) {
    //   return false;
    // }
    // // 印前状态除新建和待制作外，都视为改稿操作
    // const revisionStatus = [PrepressStatusEnum.new];
    // return !revisionStatus.includes(this.oldProjectProduct.prepressStatus);
    // return !(
    //   this.oldProjectProduct.prepressStatus === PrepressStatusEnum.new &&
    //   this.oldProjectProduct.makeStatus === ProjectProductStatusEnum.new
    // );
    return this.type === 'revision';
  }
  // 判定是否触发改稿
  public isRevision(): boolean {
    let allow = false;
    const revisionFields = [
      'customerProductName',
      'platformProductName',
      'finishHeight',
      'finishWidth',
      'prepressPicture',
      'prepressRemark',
      'visibleHeight',
      'visibleWidth',
      'topPocketSize',
      'bottomPocketSize',
      'count',
      'prepressCrafts'
    ];
    this.changeLog.forEach(item => {
      if (revisionFields.includes(item.propName.split('.')[1])) {
        allow = true;
      }
    });
    return allow;
  }

  public dialogOpen(): void {
    if (this.type === 'edit') {
      this.title = 'project.editItem';
    } else if (this.type === 'revision') {
      this.title = 'project.revision';
    }
    this.init();
  }

  public destroyed(): void {
    this.$emit('dialog-closed');
  }

  public dialogClosed(): void {
    this.loaded = false;
  }

  public async onSubmit(): Promise<void> {
    try {
      await (this.$refs.projectProductForm as ProjectProductForm).validateForm();
    } catch {
      Message.error(translation('common.submitTip'));
    }
    try {
      const validateRes = await (this.$refs.projectProductForm as ProjectProductForm).validateForm();
      if (!validateRes) {
        return;
      }
      this.setLoading(true);
      this.changeLog = this.handleChangeRecord();
      this.projectProductForm.changeLog = JSON.stringify(this.changeLog);

      Message.success(translation('operationRes.editSuccess'));
      this.$emit('item-assignment', this.projectProductForm);
      this.closeDialog();
    } catch (error) {
      if (error) messageErrors(error);
    } finally {
      this.setLoading(false);
    }
  }

  private handleChangeRecord(): Array<{
    propName: string;
    oldValue: any;
    newValue: any;
  }> {
    const changeRecord: Array<{
      propName: string;
      oldValue: any;
      newValue: any;
    }> = [];

    // 需要记录变更的属性
    const requiredRecordProps: Array<keyof SaveProjectProduct> = [
      'platformProductName',
      'backendCrafts',
      'finishHeight',
      'finishWidth',
      'prepressPicture',
      'prepressRemark',
      'remark',
      'visibleHeight',
      'visibleWidth',
      'topPocketSize',
      'bottomPocketSize',
      'pointName',
      'count',
      'prepressCrafts',
      'printingFlag',
      'backendFlag',
      'prepressFlag',
      'platformPrepressFlag',
      'installFlag',
      'logisticsFlag',
      'requiredDeliveryTime',
      'requiredArriveTime',
      'prepressSubmitCount',
      'areaPriceFlag',
      'priceUnitName',
      'priceCount',
      'salePrice',
      'priceIncludingTax',
      'taxRate',
      'totalPrice',
      'taxAmount',
      'amountIncludingTax',
      'saleUnitName',
      'unitArea',
      'totalArea'
    ];

    if (this.revision) {
      requiredRecordProps.push('reeditExplain');
    }

    // 记录时要对flag做特殊处理
    const flags: Array<keyof SaveProjectProduct> = [
      'backendFlag',
      'installFlag',
      'prepressFlag',
      'printingFlag',
      'logisticsFlag',
      'platformPrepressFlag'
    ];
    // 记录时要对time做特殊处理
    const times: Array<keyof SaveProjectProduct> = ['requiredArriveTime', 'requiredDeliveryTime'];

    for (const prop of requiredRecordProps) {
      if (this.projectProductForm[prop] === this.oldProjectProduct[prop]) {
        continue;
      }

      // 处理flag值的显示
      if (flags.includes(prop)) {
        changeRecord.push({
          propName: `projectProduct.${prop}`,
          newValue:
            this.projectProductForm[prop] === 1
              ? translation('projectProduct.flagOpen')
              : translation('projectProduct.flagClose'),
          oldValue:
            this.oldProjectProduct[prop] === 1
              ? translation('projectProduct.flagOpen')
              : translation('projectProduct.flagClose')
        });
        continue;
      }
      if (prop === 'areaPriceFlag') {
        changeRecord.push({
          propName: `projectProduct.${prop}`,
          newValue: this.projectProductForm[prop] === 1 ? translation('common.yes') : translation('common.no'),
          oldValue: this.oldProjectProduct[prop] === 1 ? translation('common.yes') : translation('common.no')
        });
        continue;
      }

      // 处理time值的显示
      if (times.includes(prop)) {
        changeRecord.push({
          propName: `projectProduct.${prop}`,
          newValue: dateFormat(this.projectProductForm[prop] as string),
          oldValue: dateFormat(this.oldProjectProduct[prop] as string)
        });
        continue;
      }

      // 处理印前工艺值的显示
      if (prop === 'prepressCrafts') {
        const craftsEnum =
          this.projectProductForm.itemType === ProjectItemTypeEnum.changeColor
            ? PrepressChangeColorCraftsEnum
            : PrepressLayoutCraftsEnum;
        const i18nKey =
          this.projectProductForm.itemType === ProjectItemTypeEnum.changeColor
            ? 'prepressChangeColorCrafts'
            : 'prepressLayoutCrafts';
        changeRecord.push({
          propName: `projectProduct.${prop}`,
          newValue: translation(`${i18nKey}.${craftsEnum[this.projectProductForm[prop]!]}`),
          oldValue: translation(`${i18nKey}.${craftsEnum[this.oldProjectProduct[prop]!]}`)
        });
        continue;
      }

      changeRecord.push({
        // 这里用projectProduct拼接，是因为这些字段的国际化key，定义在projectProduct里
        propName: `projectProduct.${prop}`,
        newValue: this.projectProductForm[prop],
        oldValue: this.oldProjectProduct[prop]
      });
    }
    return changeRecord;
  }

  /**
   * 获取详情
   */
  private init(): void {
    this.oldProjectProduct = cloneDeep(this.item);
    this.projectProductForm = {
      ...this.item
    };
    if (this.revision) {
      Vue.set(this.projectProductForm, 'reeditExplain', '');
    }
    this.loaded = true;
  }

  /**
   * 计算项目未派发生产的项次总条数
   * @returns
   */
  private calUnDistributeTotal(): void {
    if (this.isNeedProduction(this.oldProjectProduct) && !this.isNeedProduction(this.projectProductForm)) {
      // 当修改操作为 开启下发生产 => 关闭 总未派发生产项次数量 - 1
      ProjectItemModule.delUnDistributeTotal();
    } else if (!this.isNeedProduction(this.oldProjectProduct) && this.isNeedProduction(this.projectProductForm)) {
      // 当修改操作为 关闭下发生产 => 开启 总未派发生产项次数量 + 1
      ProjectItemModule.addUnDistributeTotal();
    }
  }

  /**
   * 计算项目未派发印前的项次总条数
   */
  private calUnPrepressTotal(): void {
    if (this.isNeedPrepress(this.oldProjectProduct) && !this.isNeedPrepress(this.projectProductForm)) {
      // 当修改操作为 开启下发生产 => 关闭 总未派发印前项次数量 - 1
      ProjectItemModule.delUnPrePressTotal();
    } else if (!this.isNeedPrepress(this.oldProjectProduct) && this.isNeedPrepress(this.projectProductForm)) {
      // 当修改操作为 关闭下发生产 => 开启 总未派发印前项次数量 + 1
      ProjectItemModule.addUnPrePressTotal();
    }
  }

  /**
   * 该项次是否需要生产
   * @param projectItemData
   * @returns
   */
  private isNeedProduction(projectItemData: Partial<SaveProjectProduct> | ProjectProductDetail): boolean {
    return (
      projectItemData.itemType !== ProjectItemTypeEnum.changeColor &&
      (projectItemData.prepressFlag === 1 || projectItemData.backendFlag === 1 || projectItemData.printingFlag === 1)
    );
  }

  /**
   * 该项次是否需要印前
   * @param projectItemData
   * @returns
   */
  private isNeedPrepress(projectItemData: Partial<SaveProjectProduct> | ProjectProductDetail): boolean {
    return projectItemData.platformPrepressFlag === 1;
  }
}
