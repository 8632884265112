import { logisticsService, projectShopService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { DeliveryModeEnum } from '@/resource/enum';
import { ProjectShop, ShopTagResource } from '@/resource/model';
import { convertProvinces, messageError, translation } from '@/utils';
import { telOrMobile } from '@/utils/validate';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class EditShop extends mixins(DialogMixin) {
  @Prop({
    required: true,
    type: String
  })
  public type!: 'add' | 'edit';

  @Prop({
    required: true,
    type: Object
  })
  public shop!: ProjectShop;

  public title: string = 'addDeliveryNotice.customSite';

  public shopTags: Array<ShopTagResource> = [];

  public resourceFormRules = {
    area: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('customer.selectArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    shopName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('customerShop.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    address: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('supplier.inputAddress')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    receiver: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('customerShop.inputReceiverInfo')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    receiverTel: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerShop.inputReceiverTel')));
            return;
          }
          if (!telOrMobile(value)) {
            callback(new Error(translation('common.inputLegalTel')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    sendMethod: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('projectShop.selectDeliveryMethod')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public deliveryModes = logisticsService.getDeliveryMode;

  public created(): void {
    if (!this.shop.sendMethod) {
      this.shop.sendMethod = DeliveryModeEnum.TPL;
    }
    this.reviewArea();
    if (this.type === 'add') {
      this.title = 'addDeliveryNotice.customSite';
    } else {
      this.title = 'customerShop.editShop';
    }
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
  }

  public onSubmit(): void {
    (this.$refs.shopForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        const formData = { ...this.shop, ...convertProvinces(this.shop.area || []) } as any;
        if (this.type === 'add') {
          await projectShopService.post(formData);
          Message.success(translation('operationRes.addSuccess'));
        } else {
          await projectShopService.put(formData);
          Message.success(translation('operationRes.editSuccess'));
        }
        this.$emit('add-success');
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  private resetAreaCascader(): void {
    (this.$refs.area as any).$refs.panel.clearCheckedNodes();
    (this.$refs.area as any).$refs.panel.activePath = [];
  }

  /**
   * 处理省市区数据回显
   */
  private reviewArea(): void {
    const area: Array<string> = [];
    if (this.shop.province) {
      area.push(this.shop.province);
    }
    if (this.shop.city) {
      area.push(this.shop.city);
    }
    if (this.shop.district) {
      area.push(this.shop.district);
    }
    this.shop.area = area;
  }
}
