import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
export interface IProjectItemState {
  /**
   * 未派发生产的数量
   */
  unDistributeTotal: number;
  /**
   * 未派发印前的数量
   */
  unPrePressTotal: number;

  addUnDistributeTotal: (itemChangeCount: number) => void;
  addUnPrePressTotal: (itemChangeCount: number) => void;
  delUnDistributeTotal: (itemChangeCount: number) => void;
  delUnPrePressTotal: (itemChangeCount: number) => void;
  initUnDistributeTotal: (itemCount: number) => void;
  initUnPrePressTotal: (itemCount: number) => void;
}

@Module({ dynamic: true, store, name: 'projectItem' })
class ProjectItem extends VuexModule implements IProjectItemState {
  public unDistributeTotal = 0;
  public unPrePressTotal = 0;

  /**
   * 增加未派发生产的项次条数
   */
  @Mutation
  public addUnDistributeTotal(): void {
    this.unDistributeTotal++;
  }

  /**
   * 增加未派发印前的项次条数
   */
  @Mutation
  public addUnPrePressTotal(): void {
    this.unPrePressTotal++;
  }

  /**
   * 移除未派发生产的项次条数
   */
  @Mutation
  public delUnDistributeTotal(): void {
    this.unDistributeTotal--;
  }

  /**
   * 移除未派发印前的项次条数
   */
  @Mutation
  public delUnPrePressTotal(): void {
    this.unPrePressTotal--;
  }

  /**
   * 初始化未派发生产的项次条数
   * @param itemCount 项次条数
   */
  @Mutation
  public initUnDistributeTotal(itemCount: number): void {
    this.unDistributeTotal = itemCount;
  }

  /**
   * 初始化未派发印前的项次条数
   * @param itemCount 项次条数
   */
  @Mutation
  public initUnPrePressTotal(itemCount: number): void {
    this.unPrePressTotal = itemCount;
  }
}

export const ProjectItemModule = getModule(ProjectItem);
