import { projectShopService } from '@/api';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { SelectedProjectItem, ShopDeliveryDetail } from '@/resource/model';
import { limitFutureForTimePicker, messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { MessageBoxInputData } from 'element-ui/types/message-box';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SelectProjectItem from '../select-project-item/select-project-item.vue';

@Component({
  components: { SelectProjectItem }
})
export default class ShopDeliveryDetails extends mixins(DialogMixin) {
  @Prop({ type: Number, required: true })
  public shopId!: number;

  public tableOption: OsTableOption<ShopDeliveryDetail> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true,
    rowKey: () => {
      return 'itemId';
    }
  };

  /**
   * 表格列配置
   */
  public columnOptions: Array<OsTableColumn<ShopDeliveryDetail>> = [
    {
      type: 'selection',
      prop: 'itemId',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '130px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '160px'
    },
    {
      prop: 'saleCount',
      label: 'projectShop.saleCount',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'projectShop.assignCount',
      minWidth: '150px'
    },
    {
      prop: 'planDeliveryTime',
      label: 'projectShop.plannedShippingTime',
      minWidth: '180px'
    }
  ];

  public rowOperationOptions: RowOperation<ShopDeliveryDetail> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        handleClick: (row: ShopDeliveryDetail): void => {
          this.deleteDeliver(row);
        }
      }
    ]
  };

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'project:save',
      handleClick: this.addProjectItem
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'projectShop.batchEditCount',
      operationType: 'batch',
      disabled: true,
      icon: 'el-icon-document',
      permissionCode: 'project:save',
      handleClick: this.batchEditCount
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'delivery:notice:batchDelete',
      plain: true,
      disabled: true,
      handleClick: this.batchDeleteDelivery
    }
  ];

  public selectProjectItemVisible = false;

  public selectedRows: Array<ShopDeliveryDetail> = [];

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public created(): void {
    this.getDeliveryDetails();
  }

  public addProjectItem(): void {
    this.selectProjectItemVisible = true;
  }

  public onSubmit(): void {
    this.submitLoading = true;
    const deliveryDetails = this.tableOption.data.filter(x => x.count > 0);

    projectShopService
      .saveShopDeliveryDetails(this.shopId, deliveryDetails)
      .then(() => {
        Message.success(translation('operationRes.saveSuccess'));
        this.closeDialog();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.submitLoading = false;
      });
  }

  public handleSelectedItems(selectedItems: Array<SelectedProjectItem>): void {
    const itemIds = this.tableOption.data.map(x => x.itemId);
    // 去除已选择的项次,然后组装数据
    const deliveryDetails: Array<ShopDeliveryDetail> = selectedItems
      .filter(x => !itemIds.includes(x.itemId))
      .map(x => {
        Vue.set(x, 'count', x.saleCount >= 1 ? 1 : 0);
        this.initDynamicProps(x);
        return x as ShopDeliveryDetail;
      });
    this.tableOption.data.unshift(...deliveryDetails);
  }

  /**
   * 处理可编辑单元格的点击事件
   * @param prop 处理
   * @param rowData 当前行的数据
   */
  public handleCountClick(rowData: { countInput: boolean }): void {
    rowData.countInput = true;
  }

  public handleCountInputBlur(rowData: ShopDeliveryDetail & { countInput: boolean }): void {
    if (rowData.count > rowData.saleCount) {
      rowData.count = rowData.saleCount;
    }
    rowData.countInput = !rowData.countInput;
  }

  public handleDatePickerClick(rowData: { planDeliveryTimeInput: boolean }): void {
    rowData.planDeliveryTimeInput = true;
  }

  public handleDatePickerBlur(rowData: ShopDeliveryDetail & { planDeliveryTimeInput: boolean }): void {
    rowData.planDeliveryTimeInput = !rowData.planDeliveryTimeInput;
  }

  public handleSelectionChange(selectedData: Array<ShopDeliveryDetail>): void {
    this.selectedRows = selectedData;
  }

  private deleteDeliver(row: ShopDeliveryDetail): void {
    const index = this.tableOption.data.findIndex(x => x.itemId === row.itemId);
    this.tableOption.data.splice(index, 1);
  }

  private batchDeleteDelivery(): void {
    const selectedItemIds = this.selectedRows.map(x => x.itemId);
    this.tableOption.data = this.tableOption.data.filter(x => !selectedItemIds.includes(x.itemId));
    this.clearSelection();
  }

  private clearSelection(): void {
    (this.$refs.deliverTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private batchEditCount(): void {
    this.$prompt(translation('projectShop.inputCount'), translation('tip.tipInfo'), {
      confirmButtonText: translation('button.ok'),
      cancelButtonText: translation('button.cancel'),
      inputValidator: (value: string): boolean | string => {
        if (!value) {
          return translation('projectShop.inputCount');
        }
        if (isNaN(Number(value))) {
          return translation('projectShop.formatFailed');
        }
        return true;
      }
    })
      .then(res => {
        const count = Number((res as MessageBoxInputData).value);
        this.selectedRows.forEach(x => {
          if (count > x.saleCount) {
            x.count = x.saleCount;
            return;
          }
          x.count = count;
        });
        this.clearSelection();
      })
      .catch(() => {});
  }

  private getDeliveryDetails(): void {
    this.tableOption.loading = true;
    projectShopService
      .getShopDeliveryDetails(this.shopId)
      .then(res => {
        this.tableOption.data = res.map(x => {
          this.initDynamicProps(x);
          return x;
        });
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private initDynamicProps(x: ShopDeliveryDetail | SelectedProjectItem): void {
    if (!x.hasOwnProperty('planDeliveryTime')) {
      Vue.set(x, 'planDeliveryTime', x.requiredDeliveryTime);
    }
    Vue.set(x, 'countInput', false);
    Vue.set(x, 'planDeliveryTimeInput', false);
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ShopDeliveryDetail>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add') {
        x.disabled = value.length === 0;
      }
    });
  }
}
