import { ArchiveStatusEnum } from './../../../../../../resource/enum/close-status';
import { customerProductService, projectProductService } from '@/api';
import { PrepressStatusEnum, ProjectItemTypeEnum } from '@/resource/enum';
import { CustomerProductResource, SaveProjectProduct, UnitOfMeasurementList } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import {
  limitFutureForTimePicker,
  messageError,
  translation,
  decimalToNumber6,
  getResourceWhetherStatusOptions
} from '@/utils';
import { ElForm } from 'element-ui/types/form';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SelectCustomerProduct, SelectPlatformProduct, SelectUnitMeasure } from '@/views/dialogs';
import Decimal from 'decimal.js';

@Component({
  components: { SelectCustomerProduct, SelectPlatformProduct, SelectUnitMeasure }
})
export default class ProjectProductForm extends Vue {
  @Prop({
    type: String,
    required: true,
    default: 'add'
  })
  public type!: 'add' | 'edit' | 'revision';
  @Prop({
    type: Object,
    required: true
  })
  public projectProductForm!: Partial<SaveProjectProduct>;

  @Prop({
    type: Number,
    required: true
  })
  public customerId!: number;

  public projectItemType = ProjectItemTypeEnum;

  public itemTypeOptions: NormalSelectOptions = [];
  public taxRateFlag: 0 | 1 = 0;

  public customerProduct: CustomerProductResource | null = null;

  /**
   * 口袋尺寸说明面板控制
   */
  public pocketSizeTipVisible: boolean = false;

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public formRules: { [P in keyof Partial<SaveProjectProduct>]: Array<Object> } = {
    customerProductName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('projectProduct.selectCustomerProduct')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    platformProductId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('projectProduct.selectPlatformProduct')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    count: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value <= 0) {
            callback(new Error(translation('projectProduct.inputCount')));
            return;
          }
          // 编辑表单时，销售数量做校验
          if (this.$parent.$parent.$data.type === 'edit') {
            const minCount: number = Math.max.apply(null, [
              this.projectProductForm.installCount!,
              this.projectProductForm.invoiceCount!,
              this.projectProductForm.deliveryCount!,
              this.projectProductForm.relationCount!
            ]);
            if (value < minCount) {
              callback(new Error(`${translation('projectProduct.saleCountLessThanUserNumber')} ${minCount}`));
              return;
            }
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    prepressSubmitCount: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value <= 0) {
            callback(new Error(translation('common.input') + translation('projectProduct.prepressSubmitCount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    requiredDeliveryTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('orderChange.requiredDeliveryTimeInputTip')));
            return;
          }

          if (new Date(value) < new Date()) {
            callback(new Error(translation('projectProduct.cannotLessThanCurrentTime')));
            return;
          }

          const { requiredArriveTime } = this.projectProductForm;

          if (requiredArriveTime && new Date(value) > new Date(requiredArriveTime)) {
            callback(new Error(translation('orderChange.timeErrorTip')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    requiredArriveTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('orderChange.requiredArriveTimeInputTip')));
            return;
          }

          if (new Date(value) < new Date()) {
            callback(new Error(translation('projectProduct.cannotLessThanCurrentTime')));
            return;
          }

          const { requiredDeliveryTime } = this.projectProductForm;
          if (requiredDeliveryTime && new Date(value) < new Date(requiredDeliveryTime)) {
            callback(new Error(translation('orderChange.timeErrorTip')));
            return;
          }

          callback();
        },
        trigger: 'change'
      }
    ],
    visibleWidth: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          const { visibleHeight, finishHeight, finishWidth } = this.projectProductForm;
          if (!value || value <= 0 || !visibleHeight || visibleHeight <= 0) {
            callback(new Error(translation('projectProduct.inputVisibleSize')));
            return;
          }
          if (!finishWidth || !finishHeight) {
            callback();
            return;
          }
          // 可视尺寸不能大于完成尺寸
          if (value > finishWidth || visibleHeight > finishHeight) {
            callback(new Error(translation('prepress.verifyVisibleSize')));
            return;
          }
          if (finishHeight >= 0 && finishWidth >= 0) {
            (this.$refs.projectProductForm as ElForm).clearValidate('finishWidth');
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    finishWidth: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          const { visibleWidth, visibleHeight, finishHeight } = this.projectProductForm;
          if (!value || value <= 0 || !finishHeight || finishHeight <= 0) {
            callback(new Error(translation('projectProduct.inputFinishize')));
            return;
          }

          if (!visibleWidth || !visibleHeight) {
            callback();
            return;
          }
          // 完成尺寸必须大于可视尺寸
          if (value < visibleWidth! || finishHeight < visibleHeight!) {
            callback(new Error(translation('prepress.verifyFinfishSize')));
            return;
          }
          if (visibleWidth >= 0 && visibleHeight >= 0) {
            (this.$refs.projectProductForm as ElForm).clearValidate('visibleWidth');
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    topPocketSize: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if(this.projectProductForm.pocketFlag) {
            if (!(typeof value === 'number') || value < 0) {
              callback(new Error(translation('common.input') + translation('projectProduct.topPocketSize')));
              return;
            }
          }
          
          callback();
        },
        trigger: 'blur'
      }
    ],
    bottomPocketSize: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if(this.projectProductForm.pocketFlag) {
            if (!(typeof value === 'number') || value < 0) {
              callback(new Error(translation('common.input') + translation('projectProduct.bottomPocketSize')));
              return;
            }

            if (this.projectProductForm.topPocketSize === 0 && value === 0) {
              callback(new Error(translation('projectProduct.pocketSizeCannotZero')));
              return;
            }
          }
          
          callback();
        },
        trigger: 'blur'
      }
    ],
    prepressPicture: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('prepress.inputPrepressPicture')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    prepressRemark: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('prepress.inputPrepressRemark')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    prepressCrafts: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('projectProduct.selectPrepressCrafts')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    priceUnit: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('projectProduct.priceUnitName')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    priceIncludingTax: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value && value !== 0) {
            callback(new Error(translation('common.input') + translation('projectProduct.priceIncludingTax')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    taxRate: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value && value !== 0) {
            callback(new Error(translation('common.input') + translation('projectProduct.taxRate')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public customerProductDialogVisible = false;
  public pricingUnitDialogVisble = false;

  /**
   * 印前工艺
   */
  public prepressCrafts: NormalSelectOptions = [];

  public makeProducts: Array<{
    productId: number;
    productName: string;
    backendCrafts: string;
    taxRate: number;
    saleUnit: number;
    saleUnitName: string;
  }> = [];

  /**
   * 未开启平台印前时，禁用交稿数量
   */
  public get isDisableConfirmFlag(): boolean {
    return this.projectProductForm.platformPrepressFlag === 0;
  }
  /**
   * 项次类型修改
   */
  public get isAllowEditItemType(): boolean {
    const {
      prepressStatus,
      archiveStatus,
      relationCount,
      deliveryCount,
      installCount,
      invoiceCount
    } = this.projectProductForm;
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit = true;
        break;
      case 'edit':
        isEdit =
          relationCount === 0 &&
          installCount === 0 &&
          invoiceCount === 0 &&
          deliveryCount === 0 &&
          [PrepressStatusEnum.new, PrepressStatusEnum.notMake].includes(prepressStatus!) &&
          [ArchiveStatusEnum.new, ArchiveStatusEnum.notMake].includes(archiveStatus!);
        break;
      case 'revision':
        isEdit = false;
        break;
    }
    return !isEdit;
  }

  /**
   * @基本信息
   * 控制-基本信息编辑
   */
  public get editableBaseInfo(): boolean {
    let isEdit = false;
    const {
      prepressStatus,
      archiveStatus,
      relationCount,
      deliveryCount,
      installCount,
      invoiceCount
    } = this.projectProductForm;
    switch (this.type) {
      case 'add':
        isEdit = true;
        break;
      case 'edit':
        isEdit =
          relationCount === 0 &&
          installCount === 0 &&
          invoiceCount === 0 &&
          deliveryCount === 0 &&
          [PrepressStatusEnum.new, PrepressStatusEnum.notMake].includes(prepressStatus!) &&
          [ArchiveStatusEnum.new, ArchiveStatusEnum.notMake].includes(archiveStatus!);
        break;
      case 'revision':
        isEdit = relationCount === 0 && installCount === 0 && invoiceCount === 0 && deliveryCount === 0;
        break;
    }
    return !isEdit;
  }
  /**
   * @数量
   * 控制-数量编辑
   */
  public get editableCount(): boolean {
    const { relationCount } = this.projectProductForm;
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit = true;
        break;
      case 'edit':
        isEdit = relationCount === 0;
        break;
      case 'revision':
        isEdit = relationCount === 0;
        break;
    }
    return !isEdit;
  }

  /**
   * @印前信息
   * 控制-印前信息显示
   */
  public get isShowPrepressInfo(): boolean {
    let isShow = false;
    isShow = this.projectProductForm.platformPrepressFlag === 1;
    return isShow;
  }

  /**
   * @印前信息
   * 控制-印前信息编辑
   */
  public get editablePrepressInfo(): boolean {
    let isEdit = false;
    const {
      relationCount,
      deliveryCount,
      installCount,
      invoiceCount,
      prepressStatus,
      archiveStatus,
      makeStatus
    } = this.projectProductForm;
    switch (this.type) {
      case 'add':
        isEdit = true;
        break;
      case 'edit':
        isEdit =
          [PrepressStatusEnum.new, PrepressStatusEnum.notMake].includes(prepressStatus!) &&
          [ArchiveStatusEnum.new, ArchiveStatusEnum.notMake].includes(archiveStatus!) &&
          relationCount === 0 &&
          deliveryCount === 0 &&
          installCount === 0 &&
          invoiceCount === 0;
        break;
      case 'revision':
        // 0：待排产、 待制作 1：制作中 、已完成
        isEdit = makeStatus === 0;
        break;
    }
    return !isEdit;
  }

  /**
   * @印前
   * 控制-印前工序编辑
   */
  public get editablePrepressFlag(): boolean {
    const {
      relationCount,
      deliveryCount,
      installCount,
      invoiceCount,
      prepressStatus,
      archiveStatus,
      makeStatus
    } = this.projectProductForm;
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit = [ProjectItemTypeEnum.finishedProduct].includes(this.projectProductForm.itemType!);
        break;
      case 'edit':
        isEdit =
          relationCount === 0 &&
          deliveryCount === 0 &&
          installCount === 0 &&
          invoiceCount === 0 &&
          [PrepressStatusEnum.new, PrepressStatusEnum.notMake].includes(prepressStatus!) &&
          [ArchiveStatusEnum.new, ArchiveStatusEnum.notMake].includes(archiveStatus!) &&
          makeStatus === 0 &&
          [ProjectItemTypeEnum.finishedProduct].includes(this.projectProductForm.itemType!);
        break;
      case 'revision':
        isEdit = false;
        break;
    }
    return !isEdit;
  }

  /**
   * @设计排版
   * 控制-设计排版工序编辑
   */
  public get editablePrepressDesignFlag(): boolean {
    const {
      relationCount,
      deliveryCount,
      installCount,
      invoiceCount,
      prepressStatus,
      archiveStatus,
      makeStatus
    } = this.projectProductForm;
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit =
          this.projectProductForm.platformPrepressFlag === 1 &&
          [ProjectItemTypeEnum.finishedProduct, ProjectItemTypeEnum.designArtis].includes(
            this.projectProductForm.itemType!
          );
        break;
      case 'edit':
        isEdit =
          this.projectProductForm.platformPrepressFlag === 1 &&
          relationCount === 0 &&
          deliveryCount === 0 &&
          installCount === 0 &&
          invoiceCount === 0 &&
          [PrepressStatusEnum.new, PrepressStatusEnum.notMake].includes(prepressStatus!) &&
          [ArchiveStatusEnum.new, ArchiveStatusEnum.notMake].includes(archiveStatus!) &&
          makeStatus === 0 &&
          [ProjectItemTypeEnum.finishedProduct, ProjectItemTypeEnum.designArtis].includes(
            this.projectProductForm.itemType!
          );
        break;
      case 'revision':
        isEdit = false;
        break;
    }
    return !isEdit;
  }

  /**
   * @生产文件
   * 控制-生产文件工序编辑
   */
  public get editablePrepressFinishFlag(): boolean {
    const {
      relationCount,
      deliveryCount,
      installCount,
      invoiceCount,
      prepressStatus,
      archiveStatus,
      makeStatus
    } = this.projectProductForm;
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit =
          this.projectProductForm.platformPrepressFlag === 1 &&
          [ProjectItemTypeEnum.finishedProduct, ProjectItemTypeEnum.designArtis].includes(
            this.projectProductForm.itemType!
          );
        break;
      case 'edit':
        isEdit =
          this.projectProductForm.platformPrepressFlag === 1 &&
          relationCount === 0 &&
          deliveryCount === 0 &&
          installCount === 0 &&
          invoiceCount === 0 &&
          [PrepressStatusEnum.new, PrepressStatusEnum.notMake].includes(prepressStatus!) &&
          [ArchiveStatusEnum.new, ArchiveStatusEnum.notMake].includes(archiveStatus!) &&
          makeStatus === 0 &&
          [ProjectItemTypeEnum.finishedProduct, ProjectItemTypeEnum.designArtis].includes(
            this.projectProductForm.itemType!
          );
        break;
      case 'revision':
        isEdit = false;
        break;
    }
    return !isEdit;
  }

  /**
   * @出货
   * 控制-出货工序编辑
   */
  public get editableLogisticsFlag(): boolean {
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit = [
          ProjectItemTypeEnum.finishedProduct,
          ProjectItemTypeEnum.changeColor,
          ProjectItemTypeEnum.server
        ].includes(this.projectProductForm.itemType!);
        break;
      case 'edit':
        isEdit =
          [ProjectItemTypeEnum.finishedProduct, ProjectItemTypeEnum.changeColor, ProjectItemTypeEnum.server].includes(
            this.projectProductForm.itemType!
          ) && this.projectProductForm.deliveryCount === 0;
        break;
      case 'revision':
        isEdit = false;
        break;
    }
    return !isEdit;
  }

  /**
   * @安装
   * 控制-安装工序编辑
   */
  public get editableInstallFlag(): boolean {
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit = [ProjectItemTypeEnum.finishedProduct, ProjectItemTypeEnum.server].includes(
          this.projectProductForm.itemType!
        );
        break;
      case 'edit':
        isEdit =
          [ProjectItemTypeEnum.finishedProduct, ProjectItemTypeEnum.server].includes(
            this.projectProductForm.itemType!
          ) && this.projectProductForm.deliveryCount === 0;
        break;
      case 'revision':
        isEdit = false;
        break;
    }
    return !isEdit;
  }

  /**
   * @出货信息
   * 控制-出货信息展示
   */
  public get isShowLogisticsInfo(): boolean {
    let isShow = false;
    switch (this.type) {
      case 'add':
        isShow = true;
        break;
      case 'edit':
        isShow = true;
        break;
      case 'revision':
        isShow = false;
        break;
    }
    return isShow;
  }

  /**
   * @出货信息
   * 控制-出货信息编辑
   */
  public get editableLogisticsInfo(): boolean {
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit = true;
        break;
      case 'edit':
        isEdit = true;
        break;
      case 'revision':
        isEdit = false;
        break;
    }
    return !isEdit;
  }

  /**
   * @财务信息
   * 控制-财务信息展示
   */
  public get isShowFinanceInfo(): boolean {
    let isShow = false;
    switch (this.type) {
      case 'add':
        isShow = true;
        break;
      case 'edit':
        isShow = true;
        break;
      case 'revision':
        isShow = true;
        break;
    }
    return isShow;
  }

  /**
   * @财务信息
   * 控制-财务信息编辑
   */
  public get editableFinanceInfo(): boolean {
    let isEdit = false;
    switch (this.type) {
      case 'add':
        isEdit = true;
        break;
      case 'edit':
        isEdit = this.projectProductForm.invoiceCount === 0;
        break;
      case 'revision':
        isEdit = false;
        break;
    }
    return !isEdit;
  }

  /**
   * 上口袋尺寸输入校正
   * @param value 修改后的值
   */
  public onInputTopPocketSize(value: string): void {
    const inputValue = Number(value);
    if (inputValue > 999.9) {
      this.projectProductForm.topPocketSize = 999.9;
    }
  }

  /**
   * 下口袋尺寸输入校正
   * @param value 修改后的值
   */
  public onInputBottomPocketSize(value: string): void {
    const inputValue = Number(value);
    if (inputValue > 999.9) {
      this.projectProductForm.bottomPocketSize = 999.9;
    }
  }

  // 待排产 / 待制作 可编辑
  public get isAllowEditMakeStatus(): boolean {
    return this.projectProductForm.makeStatus === 0;
  }

  public get getWhetherStatusOptions(): NormalSelectOptions {
    return getResourceWhetherStatusOptions();
  }

  public created(): void {
    this.itemTypeOptions = projectProductService.getItemType();
    this.prepressCrafts =
      this.projectProductForm.itemType === ProjectItemTypeEnum.changeColor
        ? projectProductService.getPrepressChangeColorCrafts()
        : projectProductService.getPrepressLayoutCrafts();

    if (this.projectProductForm.customerProductId) {
      this.getMakeProductByCPId(this.projectProductForm.customerProductId);
    }
  }

  //  打开客户产品选择弹窗
  public openCustomerProductDialog(): void {
    this.customerProductDialogVisible = true;
  }
  // 客户产品选中后赋值处理
  public handleSelectedCustomerProduct(customerProduct: CustomerProductResource): void {
    // 从客户产品拿取部分属性，方便后端冗余数据
    this.projectProductForm.customerProductName = customerProduct.productName;
    this.projectProductForm.customerProductId = customerProduct.id;
    this.projectProductForm.productCategoryId = customerProduct.categoryId;
    this.projectProductForm.priceUnit = customerProduct.priceUnit;
    this.projectProductForm.priceUnitName = customerProduct.priceUnitName;

    // 重置制作品
    this.projectProductForm.platformProductId = undefined;
    this.projectProductForm.platformProductName = '';
    this.projectProductForm.backendCrafts = '';
    this.projectProductForm.taxRate = 0;
    this.projectProductForm.saleUnitName = '';
    this.projectProductForm.saleUnit = undefined;
    this.getMakeProductByCPId(customerProduct.id);
    // 判断是否含税
    this.taxRateFlag = customerProduct?.taxRateFlag as 0 | 1;
    if (customerProduct?.taxRateFlag === 1) {
      this.customerProduct = customerProduct;
    } else {
      this.projectProductForm.salePrice = customerProduct?.price;
      this.countPriceIncludingTax();
    }

    this.updatePocketSizeInfo();
  }

  public handlePlatformProductChange(): void {
    const makeProduct = this.makeProducts.find(x => x.productId === this.projectProductForm.platformProductId);
    this.projectProductForm.platformProductName = makeProduct?.productName;
    this.projectProductForm.backendCrafts = makeProduct?.backendCrafts;
    this.projectProductForm.taxRate = makeProduct?.taxRate;
    this.projectProductForm.saleUnitName = makeProduct?.saleUnitName;
    this.projectProductForm.saleUnit = makeProduct?.saleUnit;
    this.isIncludedTax();

    this.updatePocketSizeInfo();
  }

  /**
   * 更新口袋尺寸信息
   */
  public updatePocketSizeInfo(): void {
    // 更新口袋尺寸信息编辑标识,匹配带有【口袋】的材质
    const matchRegex = /(上下做口袋|单边做口袋)/g;
    const matchResult = this.projectProductForm.platformProductName!.match(matchRegex);
    if (matchResult) {
      this.projectProductForm.pocketFlag = 1;
    } else {
      this.projectProductForm.pocketFlag = 0;
      this.projectProductForm.topPocketSize = 0;
      this.projectProductForm.bottomPocketSize = 0;
    }
  }

  /**
   * 是否含税
   */
  public isIncludedTax(): void {
    if (this.customerProduct?.taxRateFlag === 1) {
      // 计算单价（未税）
      // 未税单价 =  含税单价/((1+税率)
      this.projectProductForm.salePrice = decimalToNumber6(
        new Decimal(this.customerProduct!.price).div(new Decimal(this.projectProductForm.taxRate!).add(100)).mul(100)
      );
    }
    this.countPriceIncludingTax();
  }

  /**
   * 是否面积计价
   */
  public changeAreaPriceFlag(): void {
    if (this.projectProductForm.areaPriceFlag === 1) {
      // 计价数量等于计价面积
      this.projectProductForm.priceCount = this.projectProductForm.totalArea;
    } else {
      this.projectProductForm.priceCount = this.projectProductForm.count;
    }

    this.calculateAmount();
  }
  /**
   * @含税单价变动
   * 计算：单价
   */
  public changePriceIncludingTax(): void {
    if (!this.projectProductForm.priceIncludingTax) {
      this.projectProductForm.priceIncludingTax = 0;
    }
    this.projectProductForm.salePrice = decimalToNumber6(
      new Decimal(this.projectProductForm.priceIncludingTax!)
        .div(new Decimal(this.projectProductForm.taxRate!).add(100))
        .mul(100)
    );

    this.calculateAmount();
  }
  /**
   * @税率变动
   * 计算：含税单价
   */
  public countPriceIncludingTax(): void {
    if (!this.projectProductForm.taxRate) {
      this.projectProductForm.taxRate = 0;
    }
    this.projectProductForm.priceIncludingTax = decimalToNumber6(
      new Decimal(this.projectProductForm.salePrice!)
        .mul(new Decimal(this.projectProductForm.taxRate!))
        .div(new Decimal(100))
        .add(new Decimal(this.projectProductForm.salePrice!))
    );
    this.calculateAmount();
  }
  /**
   * @税率变动
   * 计算：单价
   */
  public countSalePrice(): void {
    if (!this.projectProductForm.taxRate) {
      this.projectProductForm.taxRate = 0;
    }
    this.projectProductForm.salePrice = decimalToNumber6(
      new Decimal(this.projectProductForm.priceIncludingTax!)
        .div(new Decimal(this.projectProductForm.taxRate!).add(100))
        .mul(100)
    );
    this.calculateAmount();
  }
  /**
   * @税率变动
   * 计算：含税单价 / 未税单价
   */
  public changeTaxRate(): void {
    if (this.taxRateFlag === 0) {
      this.countPriceIncludingTax();
    } else {
      this.countSalePrice();
    }
  }
  /**
   * @计算：税额、未税金额、含税金额
   */
  public calculateAmount(): void {
    // 税额 = 单价 * (税率/100) * 计价数量
    this.projectProductForm.taxAmount = decimalToNumber6(
      new Decimal(this.projectProductForm.salePrice!)
        .mul(new Decimal(this.projectProductForm.taxRate!))
        .div(new Decimal(100))
        .mul(new Decimal(this.projectProductForm.priceCount!))
    );
    // 未税金额 = 单价 * 计价数量
    this.projectProductForm.totalPrice = decimalToNumber6(
      new Decimal(this.projectProductForm.salePrice!).mul(new Decimal(this.projectProductForm.priceCount!))
    );
    // 含税金额 = 未税金额 +  税额
    this.projectProductForm.amountIncludingTax = decimalToNumber6(
      new Decimal(this.projectProductForm.totalPrice!).add(new Decimal(this.projectProductForm.taxAmount!))
    );
  }

  // 打开计量单位选择弹窗
  public openPricingUnitDialog(): void {
    this.pricingUnitDialogVisble = true;
  }
  // 计量单位选中后赋值处理
  public handleSelectedPricingUnit(rowData: UnitOfMeasurementList): void {
    this.projectProductForm.priceUnitName = rowData.name;
    this.projectProductForm.priceUnit = rowData.id;
  }
  /**
   * 项次类型发生变化
   * @param newValue
   */
  public handleItemTypeChange(newValue: ProjectItemTypeEnum): void {
    this.projectProductForm.prepressCrafts = undefined;
    switch (newValue) {
      case ProjectItemTypeEnum.finishedProduct:
        this.projectProductForm.platformPrepressFlag = 1;
        this.projectProductForm.prepressDesignFlag = 1;
        this.projectProductForm.prepressFinishFlag = 1;
        this.projectProductForm.logisticsFlag = 0;
        this.projectProductForm.installFlag = 0;

        this.projectProductForm.prepressSubmitCount = 1;
        this.projectProductForm.prepressPicture = '';
        this.projectProductForm.prepressRemark = '';
        this.prepressCrafts = projectProductService.getPrepressLayoutCrafts();
        break;
      case ProjectItemTypeEnum.changeColor:
        this.projectProductForm.platformPrepressFlag = 1;
        this.projectProductForm.prepressDesignFlag = 1;
        this.projectProductForm.prepressFinishFlag = 0;
        this.projectProductForm.logisticsFlag = 0;
        this.projectProductForm.installFlag = 0;

        this.projectProductForm.prepressSubmitCount = 1;
        this.projectProductForm.prepressPicture = '';
        this.projectProductForm.prepressRemark = '';
        this.prepressCrafts = projectProductService.getPrepressChangeColorCrafts();
        break;
      case ProjectItemTypeEnum.designArtis:
        this.projectProductForm.platformPrepressFlag = 1;
        this.projectProductForm.prepressDesignFlag = 1;
        this.projectProductForm.prepressFinishFlag = 0;
        this.projectProductForm.logisticsFlag = 0;
        this.projectProductForm.installFlag = 0;

        this.projectProductForm.prepressSubmitCount = 1;
        this.projectProductForm.prepressPicture = '';
        this.projectProductForm.prepressRemark = '';
        this.prepressCrafts = projectProductService.getPrepressLayoutCrafts();
        break;
      case ProjectItemTypeEnum.server:
        this.projectProductForm.platformPrepressFlag = 0;
        this.projectProductForm.prepressDesignFlag = 0;
        this.projectProductForm.prepressFinishFlag = 0;
        this.projectProductForm.logisticsFlag = 0;
        this.projectProductForm.installFlag = 0;

        this.projectProductForm.prepressCrafts = 11;
        this.projectProductForm.prepressSubmitCount = 0;
        this.projectProductForm.prepressPicture = '其他';
        this.projectProductForm.prepressRemark = '其他';
        break;
      case ProjectItemTypeEnum.cost:
        this.projectProductForm.platformPrepressFlag = 0;
        this.projectProductForm.prepressDesignFlag = 0;
        this.projectProductForm.prepressFinishFlag = 0;
        this.projectProductForm.logisticsFlag = 0;
        this.projectProductForm.installFlag = 0;

        this.projectProductForm.prepressCrafts = 11;
        this.projectProductForm.prepressSubmitCount = 0;
        this.projectProductForm.prepressPicture = '其他';
        this.projectProductForm.prepressRemark = '其他';
        break;
      default:
        break;
    }
  }

  public handlePlatformPrepressFlagChange(): void {
    this.synchronization(this.projectProductForm.platformPrepressFlag!);
  }
  public handlePrepressDesignFlagChange(): void {
    if (this.projectProductForm.platformPrepressFlag === 1) {
      if (this.projectProductForm.itemType === ProjectItemTypeEnum.finishedProduct) {
        if (this.projectProductForm.prepressDesignFlag === 1) {
          this.synchronization(this.projectProductForm.prepressDesignFlag!);
        }
      }
      if (!this.projectProductForm.prepressDesignFlag && !this.projectProductForm.prepressFinishFlag) {
        this.projectProductForm.prepressDesignFlag = 0;
        this.projectProductForm.prepressFinishFlag = 1;
      }
    }
  }
  public handlePrepressFinishFlagChange(): void {
    if (this.projectProductForm.platformPrepressFlag === 1) {
      if (this.projectProductForm.itemType === ProjectItemTypeEnum.finishedProduct) {
        if (this.projectProductForm.prepressFinishFlag === 0) {
          this.synchronization(1);
        }
        return;
      }
      if (!this.projectProductForm.prepressDesignFlag && !this.projectProductForm.prepressFinishFlag) {
        this.projectProductForm.prepressDesignFlag = 1;
        this.projectProductForm.prepressFinishFlag = 0;
      }
    }
  }

  public synchronization(flag: 0 | 1): void {
    if (flag) {
      this.projectProductForm.prepressDesignFlag = 1;
      this.projectProductForm.prepressFinishFlag = 1;
    } else {
      this.projectProductForm.prepressDesignFlag = 0;
      this.projectProductForm.prepressFinishFlag = 0;
    }
  }
  public validateForm(): Promise<boolean> {
    return (this.$refs.projectProductForm as ElForm).validate();
  }
  /**
   * 单位面积计算
   *【单位面积】=【完成宽度】*【完成高度】/1000000
   */
  public calculateUnitArea(): void {
    this.projectProductForm.unitArea =
      decimalToNumber6(
        new Decimal(this.projectProductForm.finishWidth as number).mul(
          new Decimal(this.projectProductForm.finishHeight as number)
        )
      ) / 1000000;
    this.calculateTotalArea();
  }
  /**
   * 合计面积计算
   * 【合计面积】=【单位面积】*【销售数量】
   */
  public calculateTotalArea(): void {
    this.projectProductForm.totalArea = decimalToNumber6(
      new Decimal(this.projectProductForm.unitArea as number).mul(new Decimal(this.projectProductForm.count as number))
    );

    this.changeAreaPriceFlag();
  }

  /**
   * 验证工序是否合法(工序至少要有一个开启的)
   */
  // private verifyProcess(): boolean {
  //   return (
  //     !!this.projectProductForm.prepressFlag! ||
  //     !!this.projectProductForm.printingFlag! ||
  //     !!this.projectProductForm.backendFlag!
  //   );
  // }

  private getMakeProductByCPId(customerProductId: number): void {
    customerProductService
      .getPlatformProductByCPId(customerProductId)
      .then(res => {
        this.makeProducts = res as any;

        this.updatePocketSizeInfo();
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('projectProductForm.finishWidth')
  private setUnitArea(): void {
    this.calculateUnitArea();
  }
  @Watch('projectProductForm.finishHeight')
  private UnitArea(): void {
    this.calculateUnitArea();
  }
  @Watch('projectProductForm.count')
  private setTotalArea(): void {
    this.calculateTotalArea();
  }
  @Watch('projectProductForm.platformPrepressFlag')
  private setPrepressSubmitCount(platformPrepressFlag: number): void {
    switch (this.projectProductForm.itemType) {
      case ProjectItemTypeEnum.finishedProduct:
        if (platformPrepressFlag === 1) {
          this.projectProductForm.prepressCrafts = undefined;
          this.projectProductForm.prepressSubmitCount = 1;
          this.projectProductForm.prepressPicture = '';
          this.projectProductForm.prepressRemark = '';
        } else {
          this.projectProductForm.prepressCrafts = 11;
          this.projectProductForm.prepressSubmitCount = 0;
          this.projectProductForm.prepressPicture = '其他';
          this.projectProductForm.prepressRemark = '其他';
        }
        break;
      default:
        break;
    }
  }
}
