import { Component, InjectReactive, Prop, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';

import { ImportRes, ProjectShopQuery, ProjectShopResource, ProjectShop } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  dateFormat,
  downloadFileByBlob,
  handleImportError,
  messageError,
  messageErrors,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { cloneDeep } from 'lodash-es';
import { projectShopService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import EditShop from './edit-shop/edit-shop.vue';
import SelectShop from './select-shop/select-shop.vue';
import ShopDeliveryDetails from './shop-delivery-details/shop-delivery-details.vue';
import { ApiResponse } from '@/api/axios';
import { ImportFile } from '@/views/dialogs';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { ActiveTab } from '../project-details';
import { DeliveryModeEnum } from '@/resource/enum';
@Component({
  name: 'ShopDetails',
  components: { EditShop, SelectShop, ShopDeliveryDetails, ImportFile }
})
export default class ShopDetails extends mixins(PagingMixin) {
  @Prop({ type: String, required: true })
  public active!: string;
  @Prop({
    type: Boolean,
    required: true
  })
  public isEditProject!: boolean;

  public tableOption: OsTableOption<ProjectShopResource> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  /**
   * 默认的表格列配置
   */
  public columnOptions: Array<OsTableColumn<ProjectShopResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'shopCode',
      label: 'customerShop.code',
      minWidth: '100px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'shopName',
      label: 'customerShop.name',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'logisticsOrderFinish',
      label: 'projectShop.logisticsProgress',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'installOrderFinish',
      label: 'projectShop.installProgress',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'customerShop.address',
      minWidth: '280px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return `
        ${(row as ProjectShopResource).province}
        ${(row as ProjectShopResource).city ? (row as ProjectShopResource).city : ''}
        ${(row as ProjectShopResource).district ? (row as ProjectShopResource).district : ''}
        ${(row as ProjectShopResource).address}`;
      }
    },
    {
      prop: 'shopTel',
      label: 'customerShop.shopTel',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'shopOwner',
      label: 'customerShop.shopOwnerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'receiver',
      label: 'customerShop.receiver',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'receiverTel',
      label: 'customerShop.receiverTel',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'sendMethod',
      label: 'logistics.deliveryMode',
      showOverflowTooltip: true,
      minWidth: '120px',
      formatter: (row: Object): string => {
        if (!DeliveryModeEnum[(row as ProjectShopResource).sendMethod]) {
          return '--';
        }
        return translation(`deliveryMode.${DeliveryModeEnum[(row as ProjectShopResource).sendMethod]}`);
      }
    },
    {
      prop: 'remark',
      label: 'logistics.deliveryRemark',
      showOverflowTooltip: true,
      minWidth: '200px'
    }
  ];

  public selectedRows: Array<ProjectShopResource> = [];

  public queryItemsOption: Array<OsQueryItemOption<ProjectShopQuery>> = [
    {
      type: 'Input',
      field: 'shopName',
      label: 'projectProduct.shopName',
      option: {
        placeholder: 'projectShop.inputShopName'
      }
    },
    {
      type: 'Input',
      field: 'shopCode',
      label: 'customerShop.code',
      option: {
        placeholder: 'projectShop.inputShopCode'
      }
    },
    {
      type: 'Slot',
      slotName: 'address',
      field: 'address',
      label: 'customer.area',
      option: {}
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'project:shop:save',
      dynamicHidden: (): boolean => {
        return !this.isEditProject;
      },
      handleClick: this.openAddDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.customSite',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'project:shop:save',
      dynamicHidden: (): boolean => {
        return !this.isEditProject;
      },
      handleClick: this.openSaveDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'projectShop.importPop',
      operationType: 'importPop',
      icon: 'el-icon-upload2',
      permissionCode: 'project:shop:importPop',
      dynamicHidden: (): boolean => {
        return !this.isEditProject;
      },
      handleClick: this.openImportPopDialog
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'projectShop.importPoster',
      operationType: 'importPoster',
      icon: 'el-icon-upload2',
      permissionCode: 'project:shop:importPoster',
      dynamicHidden: (): boolean => {
        return !this.isEditProject;
      },
      handleClick: this.openImportPosterDialog
    },
    {
      type: 'danger',
      slot: 'start',
      plain: true,
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'project:shop:batchDelete',
      handleClick: this.batchDeleteProjectShop,
      dynamicHidden: (): boolean => {
        return !this.isEditProject;
      },
      disabled: true
    },
    {
      slot: 'start',
      operationType: 'more'
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ProjectShopResource> = {
    fixed: 'right',
    width: '140px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'project:shop:edit',
        dynamicHidden: (): boolean => {
          return !this.isEditProject;
        },
        handleClick: (row: ProjectShopResource): void => {
          this.openEditDialog(row);
        }
      },
      {
        operationType: 'delivery',
        type: 'text',
        label: 'projectShop.deliveryInfo',
        icon: 'el-icon-document',
        permissionCode: '',
        dynamicHidden: (): boolean => {
          return !this.isEditProject;
        },
        handleClick: (row: ProjectShopResource): void => {
          this.openDeliveryInfoDialog(row);
        }
      }
      // {
      //   operationType: 'delete',
      //   type: 'text',
      //   label: 'button.delete',
      //   icon: 'el-icon-delete',
      //   permissionCode: 'project:shop:delete',
      //   dynamicHidden: (row: ProjectShopResource): boolean => {
      //     return !this.isEditProject && row.citeCount === 0;
      //   },
      //   handleClick: (row: ProjectShopResource): void => {
      //     this.deleteShop(row);
      //   }
      // }
    ]
  };

  public editShopVisible = false;

  /**
   * 添加门店的dialog
   */
  public addShopVisible = false;

  /**
   * 控制出货明细dialog
   */
  public deliveryInfoVisible = false;

  public importPopVisible = false;

  public importPosterVisible = false;

  /**
   * 进行出货的门店
   */
  public shopId?: number;

  /**
   * 要修改的行数据对象
   */
  public editRow: ProjectShop | null = null;
  public type: 'add' | 'edit' = 'add';

  /**
   * 项目id，用来对比id是否发生变化，变化时需要重新加载数据
   */
  public oldProjectId = 0;

  @InjectReactive()
  public readonly projectId!: number;

  public queryForm: Partial<ProjectShopQuery> = {};

  @InjectReactive()
  private readonly customerId!: number;

  @InjectReactive()
  private readonly projectCode!: string;

  public getLogisticsProgress(row: ProjectShopResource): string {
    return `${row.logisticsOrderFinish} / ${row.logisticsOrderTotal}`;
  }

  public getInstallationProgress(row: ProjectShopResource): string {
    return `${row.installOrderFinish} / ${row.installOrderTotal}`;
  }

  public activated(): void {
    this.$nextTick(() => {
      if (!isNaN(this.projectId) && this.projectId !== this.oldProjectId) {
        this.queryForm = {};
        this.oldProjectId = this.projectId;
        this.reloadData();
      }
    });
  }

  public created(): void {
    this.oldProjectId = this.projectId;
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.projectShopTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public linkToLogistics(projectShop: ProjectShopResource): void {
    this.$router.push({
      path: 'logistics',
      query: {
        shopName: projectShop.shopName,
        projectCode: this.projectCode
      }
    });
  }

  public linkToInstallation(projectShop: ProjectShopResource): void {
    this.$router.push({
      path: 'installation-order',
      query: {
        shopId: projectShop.shopId.toString(),
        projectId: this.projectId.toString()
      }
    });
  }

  public handleSelectionChange(selectedData: Array<ProjectShopResource>): void {
    this.selectedRows = selectedData;
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public editShopSuccess(data: ProjectShopResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public addShopSuccess(): void {
    this.reloadData();
  }

  public moreClick(command: 'exportPop' | 'exportPoster'): void {
    switch (command) {
      case 'exportPop':
        this.exportPop();
        break;
      case 'exportPoster':
        this.exportPoster();
        break;
      default:
        break;
    }
  }

  public downloadPopTemplate(): void {
    (this.$refs.importPopDialog as ImportFile).setDownloadLoading(true);
    projectShopService
      .downloadPopTemplate(this.customerId, this.projectId)
      .then((blob: any) => {
        downloadFileByBlob(`${translation('projectShop.popTemplateName')}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importPopDialog as ImportFile).setDownloadLoading(false);
      });
  }

  public downloadPosterTemplate(): void {
    (this.$refs.importPosterDialog as ImportFile).setDownloadLoading(true);
    projectShopService
      .downloadPosterTemplate(this.customerId, this.projectId)
      .then((blob: any) => {
        downloadFileByBlob(`${translation('projectShop.posterTemplateName')}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importPosterDialog as ImportFile).setDownloadLoading(false);
      });
  }

  public importPopSuccess(path: string): void {
    projectShopService
      .importPop({
        filePath: path,
        projectId: this.projectId,
        customerId: this.customerId
      })
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importPopVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importPopDialog as ImportFile).setLoading(false);
      });
  }

  public importPosterSuccess(path: string): void {
    projectShopService
      .importPoster({
        filePath: path,
        projectId: this.projectId,
        customerId: this.customerId
      })
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importPosterVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importPosterDialog as ImportFile).setLoading(false);
      });
  }

  private openAddDialog(): void {
    this.addShopVisible = true;
  }
  private openSaveDialog(): void {
    this.type = 'add';
    this.editRow = {
      address: '',
      // 省份
      province: '',
      // 城市
      city: '',
      // 地区
      district: '',
      // 项目id
      projectId: this.projectId,
      // 收货人
      receiver: '',
      // 收货人电话
      receiverTel: '',
      shopName: '',
      // 门店电话
      shopTel: '',
      // 用于省市区级联选择，资源本身并不包括这个字段
      area: [],
      /**
       * 发货方式
       */
      sendMethod: DeliveryModeEnum.TPL,
      remark: ''
    };

    this.editShopVisible = true;
  }

  private openEditDialog(data: ProjectShopResource | null = null): void {
    this.type = 'edit';
    if (data) {
      this.editRow = cloneDeep(data);
    }
    this.editShopVisible = true;
  }
  /**
   * 添加出货明细
   * @param shop
   */
  private openDeliveryInfoDialog(shop: ProjectShopResource): void {
    this.shopId = shop.id;
    this.deliveryInfoVisible = true;
  }

  private async batchDeleteProjectShop(): Promise<void> {
    const idList = this.selectedRows.map(x => x.id!);
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await projectShopService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          console.log(error);
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private deleteShop(data: ProjectShopResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await projectShopService.delete(data.id!);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async loadData(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const [province, city, district] = this.queryForm.address || ['', '', ''];
      this.queryForm = { ...this.queryForm, province, city, district, projectId: this.projectId };
      const res = await projectShopService.getList(this.queryForm, this.paging);
      this.tableOption.data = res.data || [];
      this.totalData = res.total;
      Promise.resolve();
    } catch (error) {
      messageError(error);
      Promise.reject();
    } finally {
      this.tableOption.loading = false;
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ProjectShopResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType === 'delete') {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('active')
  private handleActiveChange(newValue: ActiveTab): void {
    if (newValue === 'delivery') {
      this.loadData();
    }
  }

  private openImportPopDialog(): void {
    this.importPopVisible = true;
  }

  private openImportPosterDialog(): void {
    this.importPosterVisible = true;
  }

  private exportPop(): void {
    this.tableOption.loading = true;
    projectShopService
      .exportPop(this.projectId)
      .then((blob: any) => {
        downloadFileByBlob(`${translation('projectShop.popFileName')}_${dateFormat(new Date())}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private exportPoster(): void {
    this.tableOption.loading = true;
    projectShopService
      .exportPoster(this.projectId)
      .then((blob: any) => {
        downloadFileByBlob(`${translation('projectShop.posterFileName')}_${dateFormat(new Date())}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
