import { projectShopService } from '@/api';
import { CustomerShopResource, ProjectShopQuery } from '@/resource/model';
import { Component, InjectReactive, PropSync, Vue } from 'vue-property-decorator';
import { Paging } from '@/api/base';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import OsTableQuery, { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable } from '@/components';
import { convertProvinces, messageError } from '@/utils';
import { Table } from 'element-ui/types/element-ui';

@Component({})
export default class SelectShop extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;

  public pageTotal = 0;

  public tableOption: OsTableOption<CustomerShopResource> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerShopResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'shopCode',
      label: 'customerShop.code',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'shopName',
      label: 'customerShop.name',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'customerShop.address',
      showOverflowTooltip: true,
      minWidth: '280px',
      formatter: (row: Object): string => {
        return `
        ${(row as CustomerShopResource).province}
        ${(row as CustomerShopResource).city ? (row as CustomerShopResource).city : ''}
        ${(row as CustomerShopResource).district ? (row as CustomerShopResource).district : ''}
        ${(row as CustomerShopResource).address}`;
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'shopName',
      label: 'projectProduct.shopName',
      option: {
        placeholder: 'projectShop.inputShopName'
      }
    },
    {
      type: 'Input',
      field: 'shopCode',
      label: 'customerShop.code',
      option: {
        placeholder: 'projectShop.inputShopCode'
      }
    },
    {
      type: 'Slot',
      slotName: 'address',
      field: 'area',
      label: 'customer.area',
      option: {}
    }
  ];

  public selectedRows: Array<CustomerShopResource> = [];

  public submitLoading = false;

  @InjectReactive()
  private readonly projectId!: number;

  @InjectReactive()
  private readonly customerId!: number;

  private queryForm: Partial<ProjectShopQuery & { customerId: number }> = {
    city: '',
    address: [],
    shopName: ''
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public dialogOpen(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    (this.$refs.tableQuery as OsTableQuery).resetQuery();
    this.clearSelection();
  }

  public queryClick(type: 'reset' | undefined): void {
    this.reloadData();
    if (type === 'reset') {
      this.clearSelection();
    }
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getCustomerShop();
  }

  public handlePaging(): void {
    this.getCustomerShop();
  }

  public handleSelectionChange(selectedValues: Array<CustomerShopResource>): void {
    this.selectedRows = [...selectedValues];
  }

  public onSubmit(): void {
    this.submitLoading = true;
    projectShopService
      .addShop(
        this.projectId,
        this.selectedRows.map(x => x.id)
      )
      .then(() => {
        this.$emit('add-success');
        this.syncedVisible = false;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.submitLoading = false;
      });
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    this.selectedRows = [];
    ((this.$refs.customerShopTable as OsTable).tableRef as Table).clearSelection();
  }

  private getCustomerShop(): void {
    this.tableOption.loading = true;
    const queryCondition = {
      customerId: this.customerId,
      projectId: this.projectId,
      ...this.queryForm,
      ...convertProvinces(this.queryForm.address || [])
    };
    projectShopService
      .getCustomerShopList(queryCondition, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.pageTotal = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
