import { projectProductService } from '@/api';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import {
  ArchiveStatusEnum,
  ChargeUnitEnum,
  CloseStatusEnum,
  LogisticsOrderStatusEnum,
  PrepressActionEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressStatusEnum,
  ProductionActionEnum,
  ProjectItemTypeEnum
} from '@/resource/enum';
import { BillingList, ProjectProductDetail, SettlementList } from '@/resource/model';
import { isNullOrUndefinedForBaseType, messageError, translation } from '@/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface LogisticsTrackingList {
  code: string;
  deliveryShopName: string;
  status: LogisticsOrderStatusEnum;
}

@Component({
  components: {}
})
export default class ProjectProductDetails extends Vue {
  @Prop({ type: Number, required: true })
  public projectProductId!: number;

  public activeTab: 'details' | 'changeRecord' | 'makeProgress' | 'productionProgress' | 'logisticsTracking' =
    'details';

  public projectProduct: ProjectProductDetail | null = null;

  public logisticsTableOption: OsTableOption<LogisticsTrackingList> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    border: true
  };

  public logisticsColumnOptions: Array<OsTableColumn<LogisticsTrackingList>> = [
    {
      prop: 'code',
      label: 'logistics.logisticsNo',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'deliveryShopName',
      label: 'customerShop.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'logistics.status',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: object): string => {
        if (!LogisticsOrderStatusEnum[(rowData as LogisticsTrackingList).status]) {
          return translation('common.unKnownStatus');
        }
        return translation(
          `logisticsOrderStatus.${LogisticsOrderStatusEnum[(rowData as LogisticsTrackingList).status]}`
        );
      }
    }
  ];
  public projectItemBillInfoListTableOption: OsTableOption<SettlementList> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    border: true
  };

  public billInfoListColumnOptions: Array<OsTableColumn<SettlementList>> = [
    {
      prop: 'billCode',
      label: 'settlement.billCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'settlement.count',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'settlement.createUserName',
      showOverflowTooltip: true,
      minWidth: '150px'
    }
  ];

  public get closeStatus(): string {
    return translation(`closeStatus.${CloseStatusEnum[this.projectProduct!.closeStatus]}`);
  }
  public get prepressStatus(): string {
    const str = translation(`prepressStatus.${PrepressStatusEnum[this.projectProduct!.prepressStatus]}`);
    return this.projectProduct!.prepressStatus ? str : '';
  }

  public get archiveStatus(): string {
    return translation(`archiveStatus.${ArchiveStatusEnum[this.projectProduct!.archiveStatus]}`);
  }

  public get priceUnit(): string {
    return translation(`chargeUnit.${ChargeUnitEnum[this.projectProduct!.priceUnit]}`);
  }

  public get prepressCrafts(): string {
    if (this.projectProduct!.itemType === ProjectItemTypeEnum.changeColor) {
      return translation(
        `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[this.projectProduct!.prepressCrafts]}`
      );
    }
    return translation(`prepressLayoutCrafts.${PrepressLayoutCraftsEnum[this.projectProduct!.prepressCrafts]}`);
  }

  public get productionArea(): string {
    return (
      (this.projectProduct!.finishWidth / 1000) *
      (this.projectProduct!.finishHeight / 1000) *
      this.projectProduct!.count
    ).toFixed(2);
  }

  public get visibleSize(): string {
    return `${this.projectProduct!.visibleWidth}mm × ${this.projectProduct!.visibleHeight}mm`;
  }

  public get finishSize(): string {
    return `${this.projectProduct!.finishWidth}mm × ${this.projectProduct!.finishHeight}mm`;
  }

  public get itemType(): string {
    return translation(`projectItemType.${ProjectItemTypeEnum[this.projectProduct!.itemType]}`);
  }

  public get settlementProgress(): string {
    return `${this.projectProduct!.invoiceCount ?? 0} / ${this.projectProduct!.count}`;
  }
  public get installProgress(): string {
    if (this.projectProduct!.installFlag === 1) {
      return `${this.projectProduct!.installCount ?? 0} / ${this.projectProduct!.count}`;
    }
    return translation('prepress.unopened');
  }
  public get deliveryProgress(): string {
    if (this.projectProduct!.logisticsFlag === 1) {
      return `${this.projectProduct!.deliveryCount ?? 0} / ${this.projectProduct!.count}`;
    }
    return translation('prepress.unopened');
  }

  public get isConsumeRemake(): string {
    if (isNullOrUndefinedForBaseType(this.projectProduct!.attritionFlag)) {
      return '--';
    }
    return translation(`common.${this.projectProduct!.attritionFlag === 0 ? 'no' : 'yes'}`);
  }

  public flagName(flag: 1 | 0): string {
    return flag ? translation('projectProduct.flagOpen') : translation('projectProduct.flagClose');
  }

  public getPrepressActionName(action: PrepressActionEnum): string {
    return translation(`prepressAction.${PrepressActionEnum[action]}`);
  }

  public getProductionActionName(action: ProductionActionEnum): string {
    return translation(`productionAction.${ProductionActionEnum[action]}`);
  }

  public linkToLogistics(rowData: LogisticsTrackingList): void {
    this.$emit('linkToLogistics');
    this.$router.push({
      path: 'logistics',
      query: {
        logisticsNo: rowData.code
      }
    });
  }
  public linkToBillList(rowData: BillingList): void {
    this.$emit('linkToLogistics');
    this.$router.push({
      path: 'billing',
      query: {
        keywords: rowData.billCode
      }
    });
  }

  private getProjectProduct(): void {
    projectProductService
      .getDetails(this.projectProductId)
      .then(res => {
        res.projectItemHistoryList?.forEach(x => {
          x.changeLogArr = x.changeLog ? JSON.parse(x.changeLog) : [];
        });
        this.projectProduct = res;
        this.logisticsTableOption.data = res.projectItemLogisticsInfoList;
        this.projectItemBillInfoListTableOption.data = res.projectItemBillInfoList || [];
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('projectProductId', { immediate: true })
  private init(): void {
    this.getProjectProduct();
  }
}
