import { billingService, projectProductService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import {
  ArchiveStatusEnum,
  CloseStatusEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressStatusEnum,
  ProjectItemTypeEnum
} from '@/resource/enum';
import { ProjectProductList, ProjectProductListQuery } from '@/resource/model';
import { dateFormat, messageError, messageErrors, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectProjectItem extends mixins(PagingMixin, DialogMixin) {
  public tableOptions: OsTableOption<ProjectProductList> = {
    loading: false,
    data: [],
    fit: true,
    height: '500px',
    closeAdaptiveHeight: true,
    emptyText: 'billing.selectProjectFirst',
    rowKey: () => {
      return 'itemId';
    }
  };

  public columnOptions: Array<OsTableColumn<ProjectProductList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '230px',
      showOverflowTooltip: true,
      fixed: true,
      sortable: 'custom'
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'prepressStatus',
      label: 'projectProduct.prepressStatus',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ProjectProductList).prepressStatus) {
          return translation('common.unKnownStatus');
        }
        return translation(`prepressStatus.${PrepressStatusEnum[(row as ProjectProductList).prepressStatus]}`);
      }
    },
    {
      prop: 'archiveStatus',
      label: 'projectProduct.archiveStatus',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return translation(`archiveStatus.${ArchiveStatusEnum[(row as ProjectProductList).archiveStatus]}`);
      }
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '250px'
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList).visibleWidth} × ${(row as ProjectProductList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList).finishWidth} × ${(row as ProjectProductList).finishHeight}`;
      }
    },
    {
      prop: 'prepressPicture',
      label: 'projectProduct.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'projectProduct.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ProjectProductList).prepressCrafts) {
          return '--';
        }
        if ((row as ProjectProductList).itemType === ProjectItemTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as ProjectProductList).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as ProjectProductList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    // {
    //   prop: 'makeStatus',
    //   label: 'projectProduct.status',
    //   minWidth: '130px',
    //   showOverflowTooltip: true,
    //   formatter: (row: Object): string => {
    //     if (!(row as ProjectProductList).makeStatus) {
    //       return translation('common.unKnownStatus');
    //     }
    //     return translation(`projectProductStatus.${ProjectProductStatusEnum[(row as ProjectProductList).makeStatus]}`);
    //   }
    // },

    {
      prop: 'itemType',
      label: 'projectProduct.itemType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const itemType = (row as ProjectProductList).itemType;
        return translation(`projectItemType.${ProjectItemTypeEnum[itemType]}`);
      }
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'projectProduct.requiredDeliveryTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'requiredArriveTime',
      label: 'projectProduct.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList).requiredArriveTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'relationCount',
      label: 'projectProduct.relationCount',
      minWidth: '170px'
    },
    {
      prop: 'deliveryCount',
      label: 'projectProduct.deliveryCount',
      minWidth: '170px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if ((row as ProjectProductList)!.logisticsFlag === 1) {
          return `${(row as ProjectProductList)!.deliveryFinishCount ?? 0} / ${(row as ProjectProductList)!
            .deliveryCount ?? 0}`;
        }
        return translation('prepress.unopened');
      }
    },
    {
      prop: 'installCount',
      label: 'projectProduct.installCount',
      minWidth: '170px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if ((row as ProjectProductList)!.installFlag === 1) {
          return `${(row as ProjectProductList)!.installFinishCount ?? 0} / ${(row as ProjectProductList)!
            .installCount ?? 0}`;
        }
        return translation('prepress.unopened');
      }
    },
    {
      prop: 'invoiceCount',
      label: 'projectProduct.invoiceCount',
      minWidth: '170px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList)!.invoiceFinishCount ?? 0} / ${(row as ProjectProductList)!.invoiceCount ??
          0}`;
      }
    },
    {
      prop: 'closeStatus',
      label: 'projectProduct.closeStatus',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as ProjectProductList).closeStatus === CloseStatusEnum.closed
          ? translation('closeStatus.closed')
          : translation('closeStatus.normal');
      }
    },
    {
      prop: 'manualShutdownFlag',
      label: 'projectProduct.manualShutdownFlag',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as ProjectProductList).manualShutdownFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    },

    {
      prop: 'createUserName',
      label: 'project.creator',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList & { createTime: string }).createTime, 'YYYY-MM-DD HH:mm');
      }
    }
  ];

  public queryItemsOption: Array<
    OsQueryItemOption<
      ProjectProductListQuery & {
        projectIdList: Array<number>;
      }
    >
  > = [
    {
      type: 'Select',
      field: 'projectIdList',
      label: 'billing.ownerProject',
      option: {
        placeholder: 'billing.selectOwnerProject',
        clearable: true,
        filterable: true,
        multiple: true
      },
      colSpan: 8,
      optionData: []
    },
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'itemType',
      label: 'projectProduct.itemType',
      option: {
        placeholder: 'projectProduct.selectItemType'
      },
      colSpan: 8,
      optionData: projectProductService.getProjectItemType
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'projectProduct.platformProduct',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'customerProductName',
      label: 'projectProduct.customerProduct',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'attritionFlag',
      label: 'projectProduct.isRemake',
      colSpan: 8,
      option: {
        placeholder: 'common.select'
      },
      optionData: billingService.getIsRedoSelect
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      colSpan: 8,
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'common.createUserName',
      colSpan: 8,
      option: {
        placeholder: 'common.inputCreateUserName',
        clearable: true
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'deliveryNoticeList.orderCreateTime',
      colSpan: 8,
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  public selectedRows: Array<ProjectProductList> = [];

  private queryForm: Partial<
    ProjectProductListQuery & {
      projectIdList: Array<number>;
      createStartTime: string;
      createEndTime: string;
    }
  > = {};

  @InjectReactive()
  private readonly customerId!: number;
  @InjectReactive()
  private readonly projectId!: number;
  @InjectReactive()
  private readonly projectCode!: string;

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.totalData})`;
  }

  public dialogOpen(): void {
    this.getProjects();
  }

  public dialogClosed(): void {
    this.queryForm = {};
    this.tableOptions.data = [];
    this.totalData = 0;
    this.paging.showCount = 50;
    this.clearSelection();
  }

  public handleSelectionChange(selectedData: Array<ProjectProductList>): void {
    this.selectedRows = selectedData;
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getProjectItem();
  }

  public pagingData(): void {
    this.getProjectItem();
  }

  public getProjectItem(): void {
    if (this.queryForm.projectIdList?.length === 0 || !this.queryForm.projectIdList) {
      Message.warning(translation('billing.selectProjectFirst'));
      return;
    }
    this.tableOptions.loading = true;
    projectProductService
      .getProjectProductItems(this.queryForm, this.paging)
      .then(res => {
        this.tableOptions.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOptions.loading = false;
      });
  }

  public copy(): void {
    this.submitLoading = true;
    const list = [] as any;
    this.selectedRows.forEach((item: any) => {
      item.projectId = this.projectId;
      item.projectCode = this.projectCode;
      list.push(item);
    });
    projectProductService
      .batch(list)
      .then(() => {
        this.$emit('operation-success');
        this.$nextTick(() => {
          Message.success(translation('operationRes.operationSuccess'));
          this.closeDialog();
        });
      })
      .catch(error => {
        messageErrors(error);
      })
      .finally(() => {
        this.submitLoading = false;
      });
  }

  public copyAnotherItem(): void {
    this.submitLoading = true;
    const list = [] as any;
    this.selectedRows.forEach((item: any) => {
      item.remark = `调打${item.itemCode}`;
      item.projectId = this.projectId;
      item.projectCode = this.projectCode;
      list.push(item);
    });
    projectProductService
      .batch(list)
      .then(() => {
        this.$emit('operation-success');
        this.$nextTick(() => {
          Message.success(translation('operationRes.operationSuccess'));
          this.closeDialog();
        });
      })
      .catch(error => {
        messageErrors(error);
      })
      .finally(() => {
        this.submitLoading = false;
      });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private getProjects(): void {
    billingService
      .getProjectsByCustomer(this.customerId)
      .then(res => {
        const queryIndex = this.queryItemsOption.findIndex(x => x.field === 'projectIdList')!;
        this.queryItemsOption[queryIndex].optionData = res.map(x => {
          return {
            label: `[${x.projectCode}] ${x.projectName}`,
            value: x.id
          };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private clearSelection(): void {
    this.selectedRows = [];
    (this.$refs.projectProductTable as OsTable).clearSelection();
  }
}
