import { Vue, Component, ProvideReactive } from 'vue-property-decorator';
import ProjectProduct from './project-product/project-product.vue';
import ShopDetails from './shop-details/shop-details.vue';
import { ProjectResource } from '@/resource/model';
import { projectService } from '@/api';
import { dateFormat, debounce, messageError, translation } from '@/utils';
import { ProjectStatusEnum } from '@/resource/enum';
export type ActiveTab = 'product' | 'delivery';
@Component({
  name: 'ProjectDetails',
  components: { ProjectProduct, ShopDetails }
})
export default class ProjectDetails extends Vue {
  public activeTab: ActiveTab = 'product';

  public project: ProjectResource | null = null;

  public activeCollapseNames = [2];

  @ProvideReactive()
  private projectId = Number(this.$route.query.projectId);

  @ProvideReactive()
  private customerId = Number(this.$route.query.customerId);

  @ProvideReactive()
  private customerName = this.$route.query.customerName;

  @ProvideReactive()
  private projectCode = this.$route.query.code;

  public get getProjectCycle(): string {
    return this.project!.startTime && this.project!.endTime
      ? `${dateFormat(this.project!.startTime, 'YYYY-MM-DD')} ~ 
      ${dateFormat(this.project!.endTime, 'YYYY-MM-DD')}`
      : '';
  }

  public get getStatusName(): string {
    return translation(`projectStatus.${ProjectStatusEnum[this.project!.status]}`);
  }

  public get isAllowDistribute(): boolean {
    return !!this.project?.unDistributeTotal && this.project.unDistributeTotal > 0;
  }

  public get isEditProject(): boolean {
    return !(this.project?.status === ProjectStatusEnum.completed);
  }

  public activated(): void {
    if (!isNaN(this.projectId)) {
      this.projectId = Number(this.$route.query.projectId);
      this.getProject(this.projectId);
    }
    this.projectCode = this.$route.query.code;
    this.customerId = Number(this.$route.query.customerId);
    this.customerName = this.$route.query.customerName;
  }

  public created(): void {
    if (!isNaN(this.projectId)) {
      this.getProject(this.projectId);
    }
  }

  /**
   * 添加安装通知单
   */
  public jumpToAddInstallNotice(): void {
    this.$router.push({
      path: 'add-installation-notice',
      query: {
        projectId: this.project!.id.toString(),
        customerId: this.project!.customerId.toString(),
        customerName: this.project!.customerName,
        projectName: this.project!.projectName,
        projectCode: this.project!.projectCode
      }
    });
  }

  public jumpToAddDeliveryNotice(): void {
    this.$router.push({
      path: 'add-delivery-notice',
      query: {
        projectId: this.project!.id.toString(),
        customerId: this.project!.customerId.toString(),
        customerName: this.project!.customerName,
        projectName: this.project!.projectName,
        projectCode: this.project!.projectCode
      }
    });
  }

  public jumpToAddProductionOrder(): void {
    this.$router.push({
      path: '/add-production-order',
      query: {
        projectId: this.project!.id.toString(),
        projectName: this.project!.projectName,
        projectCode: this.project!.projectCode
      }
    });
  }

  public reloadDetails(): void {
    this.getProject(this.projectId);
  }

  @debounce()
  private getProject(id: number): void {
    projectService
      .getById(id)
      .then(project => {
        this.project = project;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
