import { projectShopService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';

import { InviceProjectProductItems, ProjectProductListQuery, SelectedProjectItem } from '@/resource/model';
import { messageError } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectProjectItem extends mixins(PagingMixin, DialogMixin) {
  public tableOptions: OsTableOption<SelectedProjectItem> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: () => {
      return 'itemId';
    }
  };

  public columnOptions: Array<OsTableColumn<SelectedProjectItem>> = [
    {
      type: 'selection',
      prop: 'itemId',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    // {
    //   prop: 'makeStatus',
    //   label: 'projectProduct.status',
    //   minWidth: '130px',
    //   showOverflowTooltip: true,
    //   formatter: (row: Object): string => {
    //     if (!(row as SelectedProjectItem).makeStatus) {
    //       return translation('common.unKnownStatus');
    //     }
    //     return translation(`projectProductStatus.${ProjectProductStatusEnum[(row as SelectedProjectItem).makeStatus]}`);
    //   }
    // },
    {
      prop: 'saleCount',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    }
  ];

  public queryItemsOption: Array<OsQueryItemOption<ProjectProductListQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'projectProduct.platformProduct',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
    // {
    //   type: 'Select',
    //   field: 'makeStatus',
    //   label: 'common.status',
    //   option: {
    //     placeholder: 'common.selectStatus'
    //   },
    //   optionData: projectProductService.getProjectProductStatus
    // }
  ];

  public selectedRows: Array<InviceProjectProductItems> = [];

  @InjectReactive()
  private readonly projectId!: number;

  private queryForm: Partial<ProjectProductListQuery> = {};

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.totalData})`;
  }

  public dialogOpen(): void {
    this.getProjectItem();
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public handleSelectionChange(selectedData: Array<InviceProjectProductItems>): void {
    this.selectedRows = selectedData;
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getProjectItem();
  }

  public pagingData(): void {
    this.getProjectItem();
  }

  public getProjectItem(): void {
    this.tableOptions.loading = true;
    this.queryForm.projectId = this.projectId;
    projectShopService
      .getProjectItems(this.queryForm, this.paging)
      .then(res => {
        this.tableOptions.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOptions.loading = false;
      });
  }

  public onSubmit(): void {
    this.$emit('select-confirm', this.selectedRows);
    this.closeDialog();
  }

  private clearSelection(): void {
    this.selectedRows = [];
    (this.$refs.projectProductTable as OsTable).clearSelection();
  }
}
