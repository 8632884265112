import { projectProductService } from '@/api';
import { ApiError } from '@/api/axios';
import { DialogMixin } from '@/mixins/dialog';
import { ProjectItemTypeEnum } from '@/resource/enum';
import { BatchEditTimeParams, ProjectProductList } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { dateFormat, limitFutureForTimePicker, translation, messageErrors } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class BatchEditTime extends mixins(DialogMixin) {
  @Prop({ type: Array, required: true })
  public rows!: Array<ProjectProductList>;

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public times: {
    days: Array<string>;
    requiredArriveTime: Date | string | null;
    requiredDeliveryTime: Date | string | null;
    /**
     * 后道
     */
    backendFlag: number | null;
    // /**
    //  * 确稿
    //  */
    // confirmFlag: number;
    /**
     * 安装
     */
    installFlag: number | null;
    /**
     * 出货
     */
    logisticsFlag: number | null;
    /**
     * 平台印前
     */
    platformPrepressFlag: number | null;
    /**
     * 生产印前
     */
    prepressFlag: number | null;
    /**
     * 输出
     */
    printingFlag: number | null;
  } = {
    days: [],
    requiredArriveTime: null,
    requiredDeliveryTime: null,
    /**
     * 后道
     */
    backendFlag: null,
    // /**
    //  * 确稿
    //  */
    // confirmFlag: -1,
    /**
     * 安装
     */
    installFlag: null,
    /**
     * 出货
     */
    logisticsFlag: null,
    /**
     * 平台印前
     */
    platformPrepressFlag: null,
    /**
     * 生产印前
     */
    prepressFlag: null,
    /**
     * 输出
     */
    printingFlag: null
  };

  public formRules: { [P in keyof Partial<BatchEditTimeParams>]: Array<Object> } = {
    // requiredDeliveryTime: [
    //   {
    //     required: true,
    //     validator: (rule: any, value: string, callback: Function): void => {
    //       if (!value) {
    //         callback(new Error(translation('orderChange.requiredDeliveryTimeInputTip')));
    //         return;
    //       }
    //       if (new Date(value) < new Date()) {
    //         callback(new Error(translation('projectProduct.cannotLessThanCurrentTime')));
    //         return;
    //       }
    //       const { requiredArriveTime } = this.times;
    //       if (requiredArriveTime && new Date(value) > new Date(requiredArriveTime)) {
    //         callback(new Error(translation('orderChange.timeErrorTip')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'change'
    //   }
    // ],
    // requiredArriveTime: [
    //   {
    //     required: true,
    //     validator: (rule: any, value: string, callback: Function): void => {
    //       if (!value) {
    //         callback(new Error(translation('orderChange.requiredArriveTimeInputTip')));
    //         return;
    //       }
    //       if (new Date(value) < new Date()) {
    //         callback(new Error(translation('projectProduct.cannotLessThanCurrentTime')));
    //         return;
    //       }
    //       const { requiredDeliveryTime } = this.times;
    //       if (requiredDeliveryTime && new Date(value) < new Date(requiredDeliveryTime)) {
    //         callback(new Error(translation('orderChange.timeErrorTip')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'change'
    //   }
    // ]
  };

  public optionList: NormalSelectOptions = [
    {
      label: translation('projectProduct.flagOpen'),
      value: 1
    },
    {
      label: translation('projectProduct.flagClose'),
      value: 0
    },
    {
      label: translation('projectProduct.notEdit'),
      value: null
    }
  ];

  public async submit(): Promise<void> {
    // (this.$refs.batchEditTimeForm as ElForm).validate(async (valid: boolean) => {
    //   if (!valid) {
    //     return;
    //   }
    // })
    if (!this.formValidate()) {
      Message.error(translation('projectProduct.notInputTip'));
      return;
    }
    // 校验出货时间大于当前时间
    if (this.times.requiredDeliveryTime && this.times.requiredDeliveryTime <= new Date()) {
      Message.error(translation('projectProduct.cannotLessThanCurrentTime'));
      return;
    }
    // 校验选中数据，是否可以操作
    if (this.selectVerificationData().length > 0) return;

    const changeLogList: { id: number; changeLog: string }[] = [];
    this.rows
      .map(x => {
        return {
          id: x.id,
          changeLog: this.getChangeLog(x)
        };
      })
      .forEach(item => {
        if (JSON.parse(item.changeLog).length > 0) {
          changeLogList.push(item);
        }
      });

    if (changeLogList.length === 0) {
      this.$emit('edit-success');
      this.closeDialog();
      return;
    }

    this.setLoading(true);
    try {
      await projectProductService.batchEdit({
        changeLogList,
        ...this.times
      });
      Message.success(translation('operationRes.editSuccess'));
      this.$emit('edit-success');
      this.closeDialog();
    } catch (error) {
      messageErrors(error);
    } finally {
      this.setLoading(false);
    }
  }

  public formValidate(): Boolean {
    let allow = false;
    for (const [key, value] of Object.entries(this.times)) {
      if (!['requiredArriveTime', 'requiredDeliveryTime', 'days'].includes(key) && value !== null) {
        allow = true;
      }
    }
    const { requiredArriveTime, requiredDeliveryTime } = this.times;
    if (requiredArriveTime && requiredDeliveryTime) {
      allow = true;
    }
    return allow;
  }

  public selectVerificationData(): Array<ApiError> {
    const errors: Array<ApiError> = [];
    this.rows.forEach(x => {
      let message = '';
      // console.log('判断0', isNullOrUndefinedForBaseType(0));
      // // 【平台印前】：项次下发生产后或提交印前制作后不允许开启或关闭
      // if (this.times.platformPrepressFlag !== null && ![PrepressStatusEnum.new].includes(x.prepressStatus)) {
      //   message = '项次已下发生产或提交印前后【平台印前】不允许修改';
      // }
      // 【出货开关】：项次关联发货通知单后不允许关闭
      // if (x.deliveryCount > 0) {
      //   message = `项次关联发货通知单后【出货】不允许修改`;
      // }
      // // 【安装开关】：项次关联安装通知单后不允许关闭
      // if (x.installFlag > 0) {
      //   message = `${message}、项次关联安装通知单后【安装】不允许修改`;
      // }

      if (
        [ProjectItemTypeEnum.cost, ProjectItemTypeEnum.designArtis].includes(x.itemType) &&
        (this.times.installFlag !== null || this.times.logisticsFlag !== null)
      ) {
        message = `${message} 项次类型为费用、排版的项次不允许修改工序`;
      }
      if ([ProjectItemTypeEnum.changeColor].includes(x.itemType) && this.times.installFlag !== null) {
        message = `${message} 项次类型为改色的项次不允许修改安装工序`;
      }
      // // 【生产印前】【输出】【后道】：项次开始制作后不允许开启或关闭
      // if (
      //   this.times.installFlag === 0 &&
      //   ![ProjectProductStatusEnum.new, ProjectProductStatusEnum.waitForProduction].includes(x.makeStatus)
      // ) {
      //   message = `${message}、项次开始制作后【生产印前】【输出】【后道】不允许修改`;
      // }
      if (message) {
        errors.push({
          message: `${x.itemCode}: ${message}`,
          level: 1
        });
      }
    });
    if (errors.length > 0) {
      messageErrors(errors);
    }

    return errors;
  }

  public dialogClosed(): void {
    (this.$refs.batchEditTimeForm as ElForm).resetFields();
    this.times = {
      days: [],
      requiredArriveTime: null,
      requiredDeliveryTime: null,
      /**
       * 后道
       */
      backendFlag: null,
      // /**
      //  * 确稿
      //  */
      // confirmFlag: -1,
      /**
       * 安装
       */
      installFlag: null,
      /**
       * 出货
       */
      logisticsFlag: null,
      /**
       * 平台印前
       */
      platformPrepressFlag: null,
      /**
       * 生产印前
       */
      prepressFlag: null,
      /**
       * 输出
       */
      printingFlag: null
    };
  }

  private getChangeLog(projectItem: ProjectProductList): string {
    const changeLog: Array<{
      propName: string;
      oldValue: string;
      newValue: string;
    }> = [];
    if (this.times.requiredDeliveryTime && this.times.requiredArriveTime) {
      changeLog.push(
        {
          propName: 'projectProduct.requiredDeliveryTime',
          oldValue: dateFormat(projectItem.requiredDeliveryTime),
          newValue: dateFormat(this.times.requiredDeliveryTime)
        },
        {
          propName: 'projectProduct.requiredArriveTime',
          oldValue: dateFormat(projectItem.requiredArriveTime),
          newValue: dateFormat(this.times.requiredArriveTime)
        }
      );
    }
    // 工序
    if (
      projectItem.platformPrepressFlag !== this.times.platformPrepressFlag &&
      this.times.platformPrepressFlag !== null
    ) {
      changeLog.push({
        propName: `projectProduct.platformPrepressFlag`,
        oldValue: this.getTranslation(projectItem.platformPrepressFlag),
        newValue: this.getTranslation(this.times.platformPrepressFlag)
      });
    }
    if (projectItem.prepressFlag !== this.times.prepressFlag && this.times.prepressFlag !== null) {
      changeLog.push({
        propName: `projectProduct.prepressFlag`,
        oldValue: this.getTranslation(projectItem.prepressFlag),
        newValue: this.getTranslation(this.times.prepressFlag)
      });
    }
    if (projectItem.printingFlag !== this.times.printingFlag && this.times.printingFlag !== null) {
      changeLog.push({
        propName: `projectProduct.printingFlag`,
        oldValue: this.getTranslation(projectItem.printingFlag),
        newValue: this.getTranslation(this.times.printingFlag)
      });
    }
    if (projectItem.backendFlag !== this.times.backendFlag && this.times.backendFlag !== null) {
      changeLog.push({
        propName: `projectProduct.backendFlag`,
        oldValue: this.getTranslation(projectItem.backendFlag),
        newValue: this.getTranslation(this.times.backendFlag)
      });
    }
    if (projectItem.logisticsFlag !== this.times.logisticsFlag && this.times.logisticsFlag !== null) {
      changeLog.push({
        propName: `projectProduct.logisticsFlag`,
        oldValue: this.getTranslation(projectItem.logisticsFlag),
        newValue: this.getTranslation(this.times.logisticsFlag)
      });
    }
    if (projectItem.installFlag !== this.times.installFlag && this.times.installFlag !== null) {
      changeLog.push({
        propName: `projectProduct.installFlag`,
        oldValue: this.getTranslation(projectItem.installFlag),
        newValue: this.getTranslation(this.times.installFlag)
      });
    }
    return JSON.stringify(changeLog);
  }
  private getTranslation(value: Number): string {
    let str = '';
    switch (value) {
      case 1:
        str = translation('projectProduct.flagOpen');
        break;
      case 0:
        str = translation('projectProduct.flagClose');
        break;
      default:
        break;
    }
    return str;
  }

  @Watch('times.days', { immediate: true })
  private handleTime(val: Array<string>): void {
    if (val && val.length === 2) {
      this.times.requiredDeliveryTime = new Date(val[0]);
      this.times.requiredArriveTime = new Date(val[1]);
    } else {
      this.times.requiredArriveTime = null;
      this.times.requiredDeliveryTime = null;
    }
  }
}
