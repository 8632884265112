import { consumeService, projectProductService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { ChargeUnitEnum, ProjectItemTypeEnum } from '@/resource/enum';
import {
  ProductConsumedResource,
  ProjectProductDetail,
  ProjectProductList,
  SaveProjectProduct
} from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { decimalToNumber6, messageError, translation } from '@/utils';
import Decimal from 'decimal.js';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Prop, Watch } from 'vue-property-decorator';
import ProjectProductForm from '../project-product-form/project-product-form.vue';
@Component({
  components: { ProjectProductForm }
})
export default class Consumed extends mixins(DialogMixin) {
  @Prop({ type: Object, required: true })
  public projectItem!: ProjectProductList;

  /**
   * 是否是修改耗损信息
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isEdit!: boolean;

  @InjectReactive()
  public readonly customerId!: number;

  @InjectReactive()
  public readonly customerName!: string;

  @InjectReactive()
  public readonly projectCode!: string;

  @InjectReactive()
  public readonly projectId!: number;

  public formRules = {
    attritionCount: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value <= 0) {
            callback(new Error(translation('consumed.inputConsumedCount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    // dutyUser: [
    //   {
    //     required: true,
    //     validator: (rule: any, value: number, callback: Function): void => {
    //       if (!value) {
    //         callback(new Error(translation('consumed.inputDutyUser')));
    //         return;
    //       }
    //       callback();
    //     },
    //     trigger: 'blur'
    //   }
    // ],
    calcUnit: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('consumed.selectConsumedMeasureType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    dutyReason: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('consumed.inputReason')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    attritionArea: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value < 0) {
            callback(new Error(translation('consumed.inputBrokenArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public consumeForm: Partial<ProductConsumedResource> = {
    dutyReason: '',
    attritionCount: 1,
    attritionType: '',
    attritionLink: '',
    calcUnit: ChargeUnitEnum.squareMeter,
    attritionArea: this.defaultAreaForNotSquareMeter(),
    remark: '',
    findUser: '',
    findOrganize: '',
    dutyUser: '',
    dutyOrganize: '',
    dutyOrganizeType: undefined,
    isRedo: 1
  };

  public projectProductForm: Partial<SaveProjectProduct> = {};

  /**
   * 计价单位
   */
  public priceUnits: NormalSelectOptions = consumeService.getPriceUnits();

  public get isShowResetInfo(): boolean {
    return this.consumeForm.isRedo === 1 && Object.keys(this.projectProductForm).length > 0;
  }

  public get brokenArea(): string {
    return `${translation('consumed.brokenArea')}：${(this.consumeForm.attritionArea ?? 0).toFixed(6)}㎡`;
  }

  public created(): void {
    this.getProjectProductDetails(this.projectItem.id);
  }

  public async onSubmit(): Promise<void> {
    try {
      this.setLoading(true);
      await (this.$refs.consumeForm as ElForm).validate();
      const formData = {
        itemId: this.projectProductForm.id!,
        itemCode: this.projectProductForm.itemCode!,
        customerName: this.customerName,
        ...this.consumeForm
      };
      if (this.consumeForm.isRedo === 1) {
        await (this.$refs.projectProductForm as ProjectProductForm).validateForm();
        formData.newItem = this.projectProductForm as SaveProjectProduct;
      }

      await projectProductService.consumed(formData as ProductConsumedResource);

      // 计算项目下未派发生产和未派发印前的数量
      // if (this.isNeedProduction(this.projectProductForm)) {
      //   ProjectItemModule.AddUnDistributeTotal();
      // }

      // if (this.isNeedPrepress(this.projectProductForm)) {
      //   ProjectItemModule.AddUnPrePressTotal();
      // }

      Message.success(translation('operationRes.saveSuccess'));
      this.closeDialog();
      this.$emit('consumed-success');
    } catch (error) {
      if (error) messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  public unitChanged(): void {
    if (this.consumeForm.calcUnit !== ChargeUnitEnum.squareMeter) {
      this.consumeForm.attritionArea = this.defaultAreaForNotSquareMeter();
      this.consumeForm.attritionCount = 1;
    }
  }

  @Watch('consumeForm.attritionCount')
  private calBrokenArea(): void {
    this.consumeForm.attritionArea = decimalToNumber6(
      new Decimal(
        this.consumeForm.attritionCount! *
          (this.projectItem.finishWidth / 1000) *
          (this.projectItem.finishHeight / 1000)
      )
    );
  }

  /**
   * 默认的数量下（1）的耗损面积
   * @returns
   */
  private defaultAreaForNotSquareMeter(): number {
    return decimalToNumber6(
      new Decimal((this.projectItem.finishWidth / 1000) * (this.projectItem.finishHeight / 1000) * 1)
    );
  }

  private getProjectProductDetails(id: number): void {
    projectProductService
      .getById(id)
      .then(res => {
        this.projectProductForm = {
          ...res,
          projectCode: this.projectCode,
          projectId: this.projectId,
          requiredArriveTime: '',
          requiredDeliveryTime: ''
        };
        this.consumeForm.itemId = res.id;
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 该项次是否需要生产
   * @param projectItemData
   * @returns
   */
  private isNeedProduction(projectItemData: Partial<SaveProjectProduct> | ProjectProductDetail): boolean {
    return (
      projectItemData.itemType !== ProjectItemTypeEnum.changeColor &&
      (projectItemData.prepressFlag === 1 || projectItemData.backendFlag === 1 || projectItemData.printingFlag === 1)
    );
  }

  /**
   * 该项次是否需要印前
   * @param projectItemData
   * @returns
   */
  private isNeedPrepress(projectItemData: Partial<SaveProjectProduct> | ProjectProductDetail): boolean {
    return projectItemData.platformPrepressFlag === 1;
  }
}
